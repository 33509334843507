<style scoped>
.clearfix::before,.clearfix::after{
    content: '';
    visibility: hidden;
    display: block;
    clear: both;
}
.clearfix{
    zoom: 1;
}
.container{
    padding: 30px 50px;
    background: #fff;
    height: 100vh;
    overflow: hidden;
}
.upperStory{
    margin-bottom: 20px;
    cursor: pointer;
}
.headText{
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 3px solid #ccc;
    margin-bottom: 10px;
}
.headText button{
    font-size: 16px;
    float: right;
    margin-right: 50px;
    background: none;
    padding: 5px;
    border: 2px solid #eee;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    transition: .3s;
}
.headText button:hover{
    border: 2px solid #2d8cf0;
}
.conBox{
    width: 100%;
    height: 750px;
    /* background: #EBEEF5; */
    overflow-y: auto;
}
.conBox .el-collapse{
    height: 750px;
    overflow-y: 750px;
}
.conBox .el-collapse{
    border-top: none;
}
.conBox .el-collapse-item__header{
    background: #EBEEF5 !important;
    padding: 0 20px;
}
.content{
    margin-bottom: 10px;
    background: #EBEEF5;
}
.content li{
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}
.content ul>li>div{
    float: left;
    height: 70px;
}
.billName{
    width: 45%;
}
.billName>div{
    width: 220px;
    line-height: 70px;
    text-align: center;
    background: #fce7cd;
    color: #000;
}
.billSoRange{
    width: 45%;
    height: 70px;
    border-left: 1px solid #eee;
    padding: 0 50px;
    box-sizing: border-box;
}
.billSoRange p{
    width: 100%;
    height: 35px;
    line-height: 35px;
    float: left;
}
.billEdit{
    width: 10%;
    line-height: 70px;
}
</style>
<template>
    <div class="container">
        <div class="upperStory">
            <span><router-link to="/home">返回首页</router-link></span>
        </div>
        <div class="headText">
            审批管理
            <button @click="publicFormType('newPublic'); editFun()">新建组件</button>
        </div>
        <el-collapse class="conBox" v-model="activeNames">
            <el-collapse-item class="content" title="公共表单" >
                <ul>
                   <li class="clearfix" v-for="(item,index) in infoList" :key="index"   >
                       <!-- v-show="$store.state.common.rndid.includes(item.flow_id)"  -->
                        <div class="billName"><div>{{item.flow_name}}</div></div>
                        <div class="billSoRange">
                            <p>可见范围</p>
                            <p>所有人</p>
                        </div>
                        <div class="billEdit"> <span @click="publicFormType('edit'); editFun(item)">编辑</span> </div>
                    </li>
                    <!-- <li class="clearfix" v-for="(i,index) in item.children" :key="index"  v-show="formTypeFun(i.flow_id)">

                        <div class="billName"><div>{{i.flow_name}}</div></div>
                        <div class="billSoRange">
                            <p>可见范围</p>
                            <p>所有人</p>
                        </div>
                        <div class="billEdit"> <span @click="publicFormType('edit'); editFun(i)">编辑</span> </div>
                    </li> -->
                    <p style="text-align: center; margin-top: 20px;color: #999;">已经到底了</p>
                </ul>
            </el-collapse-item>
        </el-collapse>
        <div v-if="!infoList.length">
            你还没有数据~
        </div>
    </div>
</template>
<script>
import { query_flow_info_list,query_form_replace_write,insert_flow_form_info,query_flow_info_all, } from '../../api/public.js';
import { insert_flow_info } from '../../api/shenpi.js';
export default {
    data() {
        return {
            infoList: [],
            passValue: '',
            activeNames: ['1'],

            formType: ''
        }
    },
    created() {

        this.Getquery_flow_info_list()
        // this.$store.state.rndid='随机数'

        // console.log(this.$store.state.rndid,'kxb4')
    },
    methods: {
        Getquery_flow_info_list(){
            query_flow_info_list({
            data: {
                ent_id: 'public'
                // user_id: sessionStorage.getItem('user_id')
            }
        }).then(res => {
            console.log(res.body.data)
            var data = res.body.data
            this.infoList = data;
            console.log(this.infoList)
        })
        },
        deketeSess(){
            sessionStorage.removeItem('xinjianType');
            sessionStorage.removeItem('mlbb_one_shezhi');
            sessionStorage.removeItem('mlbb_four_shezhi');
            sessionStorage.removeItem('mlbb_biaodan_sheji');
            sessionStorage.removeItem('mlbb_shenpi_zhengyi');
            sessionStorage.removeItem('mlbb_liucheng_shezhi');
            sessionStorage.removeItem('mlbb_liucheng_shezhi_list');
            sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId');
            sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission');
        },
        publicFormType(params){
            
            this.formType = params;
            sessionStorage.setItem('formType', params);
            //console.log(params,'权限');
        },

        randomCoding(){
            //创建26个字母数组
            var arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',1,2,3,4,5,6,7,8,9,0];
            var idvalue ='';
            var n = 10;//这个值可以改变，对应的生成多少个字母，根据自己需求所改
            for(var i=0;i<n;i++){
                idvalue+=arr[Math.floor(Math.random()*26)];
            }
            return idvalue;
        },
        editFun(data) {
            // 设置新建编辑权限
            console.log(data,'----------------');
            const edit =  sessionStorage.getItem('formType')
            if(edit === "newPublic"){
                sessionStorage.setItem('editData','');
                //新建
                let flow_id = 'asdffw';  // 更改flow_id
                const newDatas = {
                    ent_id: 'public',
                    flow_id,
                    flow_name: "",
                    flow_desc: "",
                    flow_group_id: "1",
                    manager: [],
                    logo_url: 'https://www.mlcbr.com/images/logo/tb1.png',
                }
                const newData = JSON.stringify(newDatas);
                sessionStorage.setItem('newPublicForm', newData);
                sessionStorage.setItem('mlbb_biaodan_sheji',"[]")
                console.log(newDatas)
               window.location.href="/ApprovalFormEdit"

            }
            if(edit === 'edit'){
                //编辑
                //编辑时获取字段 在页面内获取并监听渲染 返回字段提交
                console.log('走了编辑')
                query_flow_info_all({
                    data:{
                        ent_id: 'public',
                        flow_id: data.flow_id,
                    }
                }).then(res=>{
                    console.log(res,)
                    if(res.code == 200){
                        console.log(res);
                        sessionStorage.setItem('mlbb_biaodan_sheji', JSON.stringify(res.body.form_str));
                        sessionStorage.setItem('editData', JSON.stringify(res.body))
                        
                        this.$router.push({name:'ApprovalFormEdit'})
                    }
                })
            }
        }
    }
}
</script>
